import { Link, PageProps } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Helmet } from 'react-helmet'

import FB from '../images/fb.svg'
import IG from '../images/ig.svg'
import LN from '../images/ln.svg'
import BE from '../images/be.svg'
import { SVG, easing } from '@svgdotjs/svg.js'
import getAbsoluteOffset from '../utils/getAbsoluteOffset'
import useElementSize from '../hooks/useElementSize'
import useHeaderLines from '../hooks/useHeaderLines'

const ThankYou: React.FC<PageProps> = ({ location }) => {
  const pageRef = React.useRef(null)
  const size = useElementSize(pageRef)
  const headerLines = useHeaderLines()

  React.useEffect(() => {
    if (pageRef.current && size) {
      const headerRect = getAbsoluteOffset(document.querySelector('.header'))
      const pageRect = getAbsoluteOffset(
        document.querySelector('.page--thank-you'),
      )
      const thanksRect = getAbsoluteOffset(document.querySelector('.thanks'))

      const svg = SVG()
        .addClass('topline')
        .addTo('body')
        .size('100%', document.body.clientHeight)
        .stroke({
          color: '#000',
          width: 5,
          miterlimit: 10,
        })

      svg.polyline([
        ...headerLines,

        pageRect.left,
        headerRect.bottom,

        pageRect.left,
        thanksRect.bottom,

        document.body.clientWidth,
        thanksRect.bottom,
      ])

      return () => svg.remove()
    }
  }, [size])

  return (
    <Layout>
      <SEO title="Thank you!" />
      <Helmet
        bodyAttributes={{
          class: 'thank-you',
        }}
      />
      <main ref={pageRef} className={`container page--thank-you`}>
        <div className="thanks">
          <p>Thank you for sending us this message.</p>
          <p>We'll be back shortly.</p>
          <p>
            Meanwhile you can{' '}
            <Link className="link link--bold" to={'/projects'}>
              explore our projects
            </Link>
          </p>
          <p>or follow back black elephant in the web</p>
          <div className="social-links">
            <a
              href="https://www.facebook.com/blackelephant.design"
              target="_blank"
              rel="nopener noreferrer"
              className="social-links__link"
            >
              <FB className="social-links__icon" />
            </a>
            <a
              href="https://www.instagram.com/beidstudio/"
              target="_blank"
              rel="nopener noreferrer"
              className="social-links__link"
            >
              <IG className="social-links__icon" />
            </a>
            <a
              href="https://www.linkedin.com/in/mae-teh-a44042137/"
              target="_blank"
              rel="nopener noreferrer"
              className="social-links__link"
            >
              <LN className="social-links__icon" />
            </a>
            <a
              href="https://www.behance.net/meiteh"
              target="_blank"
              rel="nopener noreferrer"
              className="social-links__link"
            >
              <BE className="social-links__icon" />
            </a>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default ThankYou
